<template>
  <div class="middle">
    <section class="section-list">
      <div :class="['section-item-wrap', {'section-item-wrap-even': index % 2 == 0}]" v-for="(item, index) in sectionList" :key="index">
        <div class="section-item">
          <div class="desc-wrap" v-show="index % 2 == 0 || windowType == 'mobile'">
            <div class="desc-title">{{item.title}}</div>
            <div class="desc-list">
              <div class="desc-list-item" v-for="(i, j) in item.descList" :key="j">{{i}}</div>
            </div>
          </div>
          <div class="video-wrap">
            <div class="video-box">
              <img class="video-header-img" src="@/assets/images/video-header.png">
              <video :id="`section-video-${index}`"
                x5-playsinline
                playsinline="true"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x5-video-orientation="portraint"
              ></video>
            </div>
          </div>
          <div class="desc-wrap" v-show="index % 2 == 1 && windowType == 'pc'">
            <div class="desc-title">{{item.title}}</div>
            <div class="desc-list">
              <div class="desc-list-item" v-for="(i, j) in item.descList" :key="j">{{i}}</div>
            </div>
          </div>
          <img class="icon-img" v-if="item.icon" :src="item.icon">
        </div>
      </div>
    </section>
    <div class="experience-wrap" id="experienceDownload">
      <div class="experience-title">开启元宇宙体验</div>
      <div class="experience-list">
        <div class="experience-item" v-for="(item, index) in experienceList" :key="index" @click="handleExperienceItemClick(item)" :id="`download_${item.key}`">
          <img class="icon" :src="item.icon">
          <img class="icon-active" :src="item.iconActive">
          <div v-if="item.type == 'qr'" :ref="`experienceItem_${index}`" class="icon-active-qr"></div>
        </div>
      </div>
    </div>
    <div class="contact-wrap">
      <img src="@/assets/images/contact-logo.png" alt="">
      <div class="btn" @click="handleContact">商务合作，请联系我们</div>
    </div>
  </div>
</template>

<script>
import QRCode from 'qrcodejs2'
import axios from 'axios'
import { webClientUrl, baseUrl } from "@/config" 
import { isPC, isWechat, isAndroid } from '@/utils'
const video_0 = "https://alifile.cdn.tuzidong.cn/%E5%AE%98%E7%BD%91%E8%A7%86%E9%A2%91/section-0.mp4"
const video_1 = "https://alifile.cdn.tuzidong.cn/%E5%AE%98%E7%BD%91%E8%A7%86%E9%A2%91/section-1.mp4"
const video_2 = "https://alifile.cdn.tuzidong.cn/%E5%AE%98%E7%BD%91%E8%A7%86%E9%A2%91/section-2.mp4"
const video_3 = "https://alifile.cdn.tuzidong.cn/%E5%AE%98%E7%BD%91%E8%A7%86%E9%A2%91/section-3.mp4"
const video_4 = "https://alifile.cdn.tuzidong.cn/%E5%AE%98%E7%BD%91%E8%A7%86%E9%A2%91/section-4.mp4"
const video_5 = "https://alifile.cdn.tuzidong.cn/%E5%AE%98%E7%BD%91%E8%A7%86%E9%A2%91/section-5.mp4"

const defaultVideoOption =  {
  autoplay: 'any',
  loop: true,
  preload: 'auto',
  controls: false,
  muted: true,
  sources: [
    {
      src: "",
    },
  ],
}
const sectionList = [
  {
    title: '多人在线，实时语音',
    videoUrl: video_0,
    player: null,
    videojsOption: null,
    playPromise: null
  },
  {
    title: '视频直播',
    videoUrl: video_1,
    player: null,
    videojsOption: null,
    playPromise: null
  },
  {
    title: '手机移动端、PC、Web、VR全设备支持',
    videoUrl: video_2,
    player: null,
    videojsOption: null,
    playPromise: null
  },
  {
    title: '专属空间定制',
    videoUrl: video_3,
    player: null,
    videojsOption: null,
    playPromise: null
  },
  {
    title: '自主搭建虚拟空间',
    videoUrl: video_4,
    player: null,
    videojsOption: null,
    playPromise: null
  },
  {
    title: '方便管理后台',
    videoUrl: video_5,
    player: null,
    videojsOption: null,
    playPromise: null
  }
]

export default {
  name: "Middle",
  data() {
    return {
      windowType: 'pc',
      sectionList: [
        {
          title: '多人在线，实时语音',
          descList: ['打开麦克风，你就可以立即和身边的人实时语音交流', '没有延迟即可畅谈', '多种语音模式，满足不同沟通需要', '后台可控制语音状态，保证活动顺利进行'],
          icon: require("@/assets/images/section-icon-1.png"),
          player: null,
          videojsOption: null
        },
        {
          title: '视频直播',
          descList: ['支持接入直播信号流，可以吧现场画面/电脑桌面/PPT/视频/白板等', '全部投射到虚拟空间的大屏幕上，实现虚拟和现实的结合', '后台配置方便快捷，随时开启随时直播'],
          player: null,
          videojsOption: null
        },
        {
          title: '手机移动端、PC、Web、VR全设备支持',
          descList: ['云活动提供手机客户端、PC客户端、Web链接、VR设备客户端', '方便所以用户以最合适的方式访问，并且四端数据互通互联'],
          player: null,
          videojsOption: null
        },
        {
          title: '专属空间定制',
          descList: ['支持虚拟空间3D定制', '支持设计全新抽象场景', '支持针对线下场景进行高仿真还原', '风格多样，随意选择', '专属定制，充分满足个性化需求'],
          player: null,
          videojsOption: null
        },
        {
          title: '自主搭建虚拟空间',
          descList: ['云活动提供自研的虚拟空间编辑器，无需工作人员协助，任何人都可以通过拖拉拽的方式轻松编辑3D场景，搭建自己的虚拟空间', '操作简单，轻松布置', '自由度高，可随意放置图片、视频、直播和墙面'],
          player: null,
          videojsOption: null
        },
        {
          title: '方便管理后台',
          descList: ['云活动提供web端在线管理后台，可以自主设置参会密码、白名单、修改活动相关信息等', '可对活动进行实时管理，控制活动的进展', '后台有多种活动分享链接，让参会更方便'],
          icon: require("@/assets/images/section-icon-2.png"),
          player: null,
          videojsOption: null
        }
      ],
      experienceList: [
        {
          name: "Web端",
          key: "web",
          icon: require('@/assets/images/download-web.png'),
          iconActive: require('@/assets/images/download-web-right.png'),
          type: 'link',
          url: webClientUrl
        },
        {
          name: "Windows",
          key: "windows",
          icon: require('@/assets/images/download-windows.png'),
          iconActive: require('@/assets/images/download-click.png'),
          type: 'download',
          url: ''
        },
        {
          name: "ios",
          key: "ios",
          icon: require('@/assets/images/download-ios.png'),
          iconActive: require('@/assets/images/download-scan.png'),
          type: 'qr',
          url: ''
        },
        {
          name: "Android",
          key: "android",
          icon: require('@/assets/images/download-android.png'),
          iconActive: require('@/assets/images/download-scan.png'),
          type: 'qr',
          url: ''
        },
        {
          name: "Pico Neo 3",
          key: "vr",
          icon: require('@/assets/images/download-vr.png'),
          iconActive: require('@/assets/images/download-click.png'),
          type: 'download',
          url: ''
        }
      ]
    }
  },
  watch: {
    $route: {
      handler(val) {
        if(val.params.key) {
          this.$nextTick(async () => {
            await this.getExperienceUrl()
            const experienceDownloadDom = document.getElementById('experienceDownload')
            experienceDownloadDom.scrollIntoView({
              behavior: 'auto',
              block: 'center'
            })
            const downloadKey = val.params.key
            const downloadDom = document.getElementById(`download_${downloadKey}`)
            downloadDom.click()
          })
        } else {
          this.getExperienceUrl()
        }
      },
      immediate: true,
      deep: true
    },
    "$store.state.WeixinJSBridgeReady" : {
      handler(n) {
        if(n && isWechat() && !isPC()) {
          sectionList.map((item, index) => {
            this.initPlayer(index)
          })
          this.$nextTick(() => {
            this.handleWindowSize()
            this.handleObserverVideo()
          })
        }
      }, 
      immediate: true
    }
  },
  created() {
    this.toggleAppDownloadType()
  },
  mounted() {
    if(!isWechat() || this.$store.state.WeixinJSBridgeReady || isPC()) {
      sectionList.map((item, index) => {
        this.initPlayer(index)
      })
      this.$nextTick(() => {
        this.handleWindowSize()
        this.handleObserverVideo()
      })
    }
  },
  beforeDestroy() {
    this.destoryVideo()
  },
  methods: {
    toggleAppDownloadType() {
      const isPCFlag = isPC()
      const isWechatFlag = isWechat()
      if(isWechatFlag) {
        this.experienceList[1].type = 'wx'
        this.experienceList[2].type = 'wx'
        this.experienceList[3].type = 'wx'
        this.experienceList[4].type = 'wx'
        this.experienceList[2].iconActive = require('@/assets/images/download-click.png')
        this.experienceList[3].iconActive = require('@/assets/images/download-click.png')
        return
      }
      if(isPCFlag) {
        this.experienceList[2].type = 'qr'
        this.experienceList[3].type = 'qr'
        this.experienceList[2].iconActive = require('@/assets/images/download-scan.png')
        this.experienceList[3].iconActive = require('@/assets/images/download-scan.png')
      } else {
        this.experienceList[2].type = 'download'
        this.experienceList[3].type = 'download'
        this.experienceList[2].iconActive = require('@/assets/images/download-click.png')
        this.experienceList[3].iconActive = require('@/assets/images/download-click.png')
      }
    },
    async getExperienceUrl() {
      const downloadUrlRes = await axios.get(`${baseUrl}/index.php?ac=admin.api.appInfo.getDownloadUrl`)
      if(downloadUrlRes.data.errorcode == 'ok') {
        const result = downloadUrlRes.data.result
        this.experienceList[1].url = result.windows.value
        this.experienceList[2].url = result.ios.value
        this.experienceList[3].url = result.android.value
        this.experienceList[4].url = result.pico.value
        this.$nextTick(() => {
          this.createExperienceQrCode()
        })
      }else {
        console.error("获取下载地址失败")
      }
    },
    handleWindowSize() {
      let clientWidth = document.body.clientWidth;
      if (clientWidth < 888) {
        this.windowType = "mobile";
      } else {
        this.windowType = "pc";
      }
      window.addEventListener("resize", () => {
        let clientWidth = document.body.clientWidth;
        if (clientWidth < 888) {
          this.windowType = "mobile";
        } else {
          this.windowType = "pc";
        }
      });
    },
    initPlayer(videoIndex) {
      sectionList[videoIndex].videojsOption = JSON.parse(JSON.stringify(defaultVideoOption))
      sectionList[videoIndex].videojsOption.sources[0].src = sectionList[videoIndex].videoUrl;
      sectionList[videoIndex].player = this.$videojs(
        `section-video-${videoIndex}`,
        sectionList[videoIndex].videojsOption,
        function onPlayerReady() {
          // console.log('onPlayerReady', this);
          // this.play()
        }
      );
    },
    destoryVideo() {
      sectionList.map(item => {
        if(item.player) {
          item.player.dispose()
          item.player = null
        }
        if(item.playPromise) {
          item.playPromise = null
        }
      })
    },
    createExperienceQrCode() {
      for(let i = 0, len = this.experienceList.length; i < len; i++) {
        let qrDownload = this.experienceList[i].type == 'qr' 
        if(qrDownload) {
          this.createQrCode(this.experienceList[i].url, this.$refs[`experienceItem_${i}`][0])
        }
      }
    },
    // 创建二维码
    createQrCode(url, dom) {
      new QRCode(dom, {
        text: url, // 需要转换为二维码的内容
        width: 78,
        height: 78,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
    },
    // 体验-点击
    handleExperienceItemClick(item) {
      if(!item.url) {
        console.log("下载地址为空！")
        return
      }
      if(item.type == 'qr') {
        return
      }
      if(item.type == 'wx') {
        this.$router.push({
          name: 'WxRedirect',
          query: {
            key: item.key
          }
        })
        return
      }
      if(item.type == 'link') {
        window.open(item.url, '_blank');
        return
      } else if (item.type == 'download') {
        window.open(item.url, '_parent');
      }
    },
    // 联系我们
    handleContact() {
      this.$router.push({
        name: "About",
        params: {
          contact: true
        }
      })
      const appContainerDom = document.getElementById('app')
      appContainerDom.scrollTop = 0
    },
    // 只有在视窗内才可播放视频
    handleObserverVideo() {
      const ovserverOption = {
        root: null, // 未指定或者为null，则默认为浏览器视窗。
        rootMargin: '0px',
        threshold: 0.25 // 当target元素的在root元素中的可见性超过 25% 的时候，回调函数将会被执行
      }
      const observer = new IntersectionObserver((entries) => {
        for(let i = 0, len = entries.length; i < len; i++) {
          const index = Number(entries[i].target.id.split('-')[2])
          if(sectionList[index].player) {
            if(entries[i].isIntersecting) {
              sectionList[index].playPromise = sectionList[index].player.play()
            } else {
              sectionList[index].playPromise && sectionList[index].playPromise.then(() => {
                sectionList[index].player.pause()
              })
            }
          }
        }
      }, ovserverOption);
      for(let i = 0, len = sectionList.length; i < len; i++) {
        let video = document.getElementById(`section-video-${i}`)
        observer.observe(video)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.middle {
  .section-list {
    width: 100%;
    .section-item-wrap {
      margin: auto;
      background: #f5f7fa;
      padding: 80px 20px;
      position: relative;
      &.section-item-wrap-even {
        background: #ffffff;
      }
      &:nth-child(3) {
        .section-item {
          .video-wrap {
            &::before {
              content: '';
              display: inline-block;
              width: 222px;
              height: 222px;
              border-radius: 50%;
              position: absolute;
              right: -45%;
              top: -4%;
              opacity: 0.19;
              transform: rotate(-22deg) translate(-50%, -50%);
              background-image: linear-gradient(197deg, #A5FF88 0%, #99F8FC 100%);
              // z-index: 1;
              filter: blur(10px);
            }
          }
        }
      }
      &:nth-child(5) {
        .section-item {
          .video-wrap {
            &::before {
              content: '';
              display: inline-block;
              width: 222px;
              height: 222px;
              border-radius: 50%;
              position: absolute;
              right: -16%;
              top: -22%;
              opacity: 0.19;
              transform: rotate(-22deg);
              background-image: linear-gradient(197deg, #A5FF88 0%, #99F8FC 100%);
              // z-index: 1;
              filter: blur(10px);
            }
          }
        }
      }
      &:last-child {
        overflow: hidden;
        .section-item {
          &::before {
            content: '';
            display: inline-block;
            width: 360px;
            height: 360px;
            border-radius: 50%;
            position: absolute;
            right: 0;
            bottom: 0;
            border-radius: 50%;
            transform: rotate(40deg) translate(40%, 20%);
            background-image: linear-gradient(252deg, #ECFCF6 45%, rgba(249,253,253,0.00) 100%, rgba(249,253,253,0.00) 100%);
            // z-index: 1;
            filter: blur(10px);
          }
          .icon-img {
            right: 50%;
            bottom: 0;
            transform: translate(36%, 25%);
            width: 394px;
          }
        }
      }
      .section-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: no-wrap;
        max-width: 1200px;
        margin: auto;
        .desc-wrap {
          height: 322px;
          max-width: 548px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          flex: 1;
          padding: 0 10px;
          .desc-title {
            position: relative;
            font-size: 28px;
            color: #364437;
            letter-spacing: 0;
            text-align: left;
            font-weight: 500;
            line-height: 40px;
            padding-left: 55px;
            &::before {
              content: '';
              width: 41px;
              height: 29px;
              display: inline-block;
              background: url('~@/assets/images/icon-section-title.png') center / 100% 100% no-repeat;
              position: absolute;
              top: 6px;
              left: 0;
            }
          }
          .desc-list {
            .desc-list-item {
              position: relative;
              font-size: 16px;
              color: #364437;
              letter-spacing: 0;
              text-align: left;
              font-weight: 400;
              line-height: 27px;
              margin-bottom: 20px;
              padding-left: 16px;
              &::before {
                content: '';
                width: 6px;
                height: 6px;
                display: inline-block;
                border-radius: 50%;
                background: #71F447;
                position: absolute;
                top: 11px;
                left: 0;
              }
            }
          }
        }
        .video-wrap {
          position: relative;
          // height: 322px;
          max-width: 548px;
          margin: 0;
          flex: 1;
          padding: 0 10px;
          .video-box {
            position: relative;
            width: 100%;
            // height: 100%;
            background: #555556;
            box-shadow: 0px 2px 5px 0px rgba(39,39,39,0.09);
            border-radius: 12px;
            overflow: hidden;
            .video-header-img {
              // width: 100%;
              height: 25px;
              object-fit: contain;
              &+div {
                width: 100%;
                height: auto;
              }
            }
            video {
              width: 100%;
            }
          }
        }
        .icon-img {
          position: absolute;
          right: 50%;
          bottom: 0;
          transform: translate(54%, 25%);
          width: 394px;
        }
        
      }
    }
  }
  .experience-wrap {
    width: 100%;
    // height: 550px;
    padding: 80px 20px 120px;
    background: #ffffff;
    .experience-title {
      font-size: 28px;
      color: #142722;
      letter-spacing: 0;
      text-shadow: 0 2px 3px rgba(69,69,69,0.07);
      font-weight: 550;
      line-height: 40px;
      margin-bottom: 72px;
      text-align: center;
    }
    .experience-list {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: 1200px;
      margin: auto;
      .experience-item {
        width: 234px;
        height: 234px;
        cursor: pointer;
        position: relative;
        flex-shrink: 0;
        img {
          display: inline-block;
        }
        &:hover {
          // .icon {
          //   transform: translate(-50%, -50%) scale(0.8, 0.8);
          //   transition: all 0.1s ease-out;
          // }
          .icon-active {
            transform: translate(-50%, -50%) scale(1, 1);
            transform-origin: 209px 25px;
            transition: all 0.15s ease-in-out;
          }
          .icon-active-qr {
            transform: translate(-50%, -50%) scale(1, 1);
            transform-origin: top right;
            transition: all 0.1s ease-in-out;
          }
        }
        .icon {
          position: absolute;
          width: 184px;
          height: 184px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(1, 1);
          // transition: all 0.1s ease-out;
        }
        .icon-active {
          position: absolute;
          width: 234px;
          height: 234px;
          top: 52%;
          left: 50%;
          transform-origin: 209px 25px;
          transform: translate(-50%, -50%) scale(0, 0);
          transition: all 0.15s ease-in-out;
        }
        .icon-active-qr {
          z-index: 2;
          position: absolute;
          width: 78px;
          height: 78px;
          top: 104px;
          left: 116px;
          transform-origin: top right;
          transform: translate(-50%, -50%) scale(0, 0);
          transition: all 0.1s ease-in-out;
          border-radius: 10px;
        }
      }
    }
  }
  .contact-wrap {
    user-select: none;
    height: 284px;
    width: 100%;
    position: relative;
    text-align: center;
    padding: 70px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("~@/assets/images/bg-about.png") center / 100% 100% no-repeat;
    img {
      display: inline-block;
      margin-bottom: 40px;
    }
    .btn {
      font-size: 18px;
      color: #FFFFFF;
      display: inline-block;
      cursor: pointer;
      user-select: none;
      padding: 15px 35px;
      background-image: linear-gradient(90deg, #70F447 0%, #2DC5A6 100%);
      border-radius: 27px;
    }
  }
}

@media screen and (max-width: 888px){
  .middle {
    .section-list .section-item-wrap {
      padding: 20px;
      .section-item {
        flex-wrap: wrap;
        .desc-wrap {
          height: auto;
          width: 100%;
          max-width: 100%;
          flex-basis: 100%;
          .desc-title {
            margin-bottom: 16px;
          }
        }
        .video-wrap {
          width: 100%;
          max-width: 100%;
          flex-basis: 100%;
        }
        .icon-img {
          display: none;
        }
      }
    }
    .experience-wrap {
      padding: 40px 20px;
      .experience-title {
        margin-bottom: 20px;
      }
      .experience-list {
        .experience-item {
          width: 140px;
          height: 140px;
          .icon {
            width: 110px;
            height: 110px;
          }
          .icon-active {
            width: 140px;
            height: 140px;
          }
          .icon-active-qr {
            width: 46px;
            height: 46px;
            top: 62px;
            left: 70px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 420px){
  .middle {
    .section-list .section-item-wrap {
      padding: 10px;
      .section-item {
        flex-wrap: wrap;
        .desc-wrap {
          height: auto;
          width: 100%;
          max-width: 100%;
          flex-basis: 100%;
          padding: 10px;
          .desc-title {
            margin-bottom: 12px;
            font-size: 18px;
            line-height: 24px;
            padding-left: 32px;
            &::before {
              width: 24px;
              height: 18px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .desc-list {
            .desc-list-item {
              margin-bottom: 6px;
            }
          }
        }
        .video-wrap {
          width: 100%;
          max-width: 100%;
          flex-basis: 100%;
        }
        .icon-img {
          display: none;
        }
      }
    }
    .experience-wrap {
      padding: 20px 20px;
      .experience-title {
        margin-bottom: 10px;
        font-size: 24px;
      }
      .experience-list {
        .experience-item {
          width: 110px;
          height: 110px;
          .icon {
            width: 86px;
            height: 86px;
          }
          .icon-active {
            width: 110px;
            height: 110px;
          }
          .icon-active-qr {
            width: 46px;
            height: 46px;
            top: 62px;
            left: 70px;
          }
        }
      }
    }
    .contact-wrap {
      height: auto;
      padding: 25px 0;
      img {
        transform: scale(0.8);
        margin-bottom: 20px;
      }
      .btn {
        padding: 10px 28px;
        font-size: 16px;
      }
    }
  }
}
</style>