<template>
  <div class="about" ref="about">
    <div class="top-wrap">
      <div class="top-title">
        <img class="top-title-img" src="@/assets/images/header-img.png"/>
        <img class="top-title-img-border" src="@/assets/images/title-border.png"/>
        <div class="top-title-text">元宇宙线上活动平台</div>
      </div>
    </div>
    <div class="content-wrap">
      <div class="side-bar">
        <div :class="['side-bar-item', {'active': activeSideBarIndex == index}]" v-for="(item, index) in sideBarList" :key="index" @click="handleSideBarClick(index)">
          <div class="icon"></div>
          <div class="label">{{ item }}</div>
        </div>
      </div>
      <div class="content">
        <div class="section section-company">
          <div class="section-title">公司介绍</div>
          <div class="section-content">
            <div class="about-video-box">
              <video id="about-video"
                x5-playsinline
                playsinline="true"
                webkit-playsinline="true"
                x-webkit-airplay="true"
                x5-video-player-type="h5"
                x5-video-player-fullscreen="true"
                x5-video-orientation="portraint"
                ></video>
            </div>
            <div class="introduce">
              <p>挖开兔子洞(北京)科技有限公司是由北京市海淀区政府投资的国家级高新技术企业致力于用元宇宙的方式打造全新的线上活动体验。公司总部位于北京市海淀区中关村创业大街，研发和技术人员占比超过70%，其中硕博比例超过50%，除北京之外，还设有斯德哥尔摩创新中心，南京工程中心，青岛研发中心等。</p>
              <p>公司主要研发领域覆盖计算机图形学、AIGC、计算机视觉、深度学习等。研发人才主要来自于瑞典皇家理工学院、清华大学、复旦大学、南京大学、中科大等高效和研究机构。</p>
            </div>
          </div>
        </div>
        <div class="section section-contact" id="contact">
          <div class="section-title">联系我们</div>
          <div class="section-content">
            <img class="icon-img" src="@/assets/images/icon-contact.png">
            <div class="contact">
              <div>电话咨询</div>
              <div>兔子洞官方客服热线</div>
              <div class="phone">400 8675 521</div>
              <div class="time">人工服务时间：工作日 9:30-20:00</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom/>
  </div>
</template>

<script>
import Bottom from "../home/components/Bottom"
import { isPC, isWechat, isAndroid } from '@/utils'

let player = null
let videojsOption = {
  autoplay: 'any',
  loop: true,
  preload: 'auto',
  controls: false,
  muted: true,
  sources: [
    {
      src: "https://alifile.cdn.tuzidong.cn/%E5%AE%98%E7%BD%91%E8%A7%86%E9%A2%91/home.mp4",
    },
  ],
}

export default {
  name: "About",
  components: {
    Bottom
  },
  data() {
    return {
      sideBarList: ['兔子洞团队', '联系我们'],
      activeSideBarIndex: 0
    }
  },
  watch: {
    $route: {
      handler(val) {
        if (val.params.contact) {
          this.$nextTick(() => {
            const contactDom = document.getElementById('contact')
            contactDom.scrollIntoView({
              behavior: 'smooth',
              block: 'center'
            })
          })
        }
      },
      immediate: true,
      deep: true
    },
    "$store.state.WeixinJSBridgeReady" : {
      handler(n) {
        if(n && isWechat() && !isPC()) {
          this.initPlayer()
          this.$nextTick(() => {
            this.handleObserverVideo()
          })
        }
      }, 
      immediate: true
    }
  },
  mounted() {
    if(!isWechat() || this.$store.state.WeixinJSBridgeReady || isPC()) {
      this.initPlayer()
      this.$nextTick(() => {
        this.handleObserverVideo()
      })
    }
    
  },
  beforeDestroy() {
    this.destoryVideo()
  },
  methods: {
    handleSideBarClick(index) {
      this.activeSideBarIndex = index
      this.handleSidebarJump(index)
    },
    handleSidebarJump(index) {
      const sections = document.getElementsByClassName('section')
      sections[index].scrollIntoView({
        behavior: 'auto',
        block: 'center'
      })
    },
    initPlayer() {
      player = this.$videojs(
        `about-video`,
        videojsOption,
        function onPlayerReady() {
          // this.play()
        }
      );
    },
    destoryVideo() {
      if (player) {
        player.dispose()
        player = null
      }
    },
    // 只有在视窗内才可播放视频
    handleObserverVideo() {
      const ovserverOption = {
        root: null, // 未指定或者为null，则默认为浏览器视窗。
        rootMargin: '0px',
        threshold: 0.25 // 当target元素的在root元素中的可见性超过 25% 的时候，回调函数将会被执行
      }
      const observer = new IntersectionObserver((entries) => {
        for(let i = 0, len = entries.length; i < len; i++) {
          if(player) {
            if(entries[i].isIntersecting) {
              player.play()
            } else {
              player.pause()
            }
          }
        }
      }, ovserverOption);
      const aboutVideo = document.getElementById("about-video")
      observer.observe(aboutVideo)
    }
  }
}
</script>

<style lang="scss" scoped>
.about {
  position: relative;
  width: 100%;
  overflow-x: hidden;
  background: #f5f7fa;
  .top-wrap {
    width: 100vw;
    height: 284px;
    margin: 0 auto 0;
    background: url("~@/assets/images/bg-about.png") center / 100% 100% no-repeat;
    .top-title {
      position: absolute;
      max-width: 1000px;
      margin: 0 auto;
      top: 121px;
      left: 0;
      user-select: none;
      position: relative;
      .top-title-img {
        width: 103px;
        margin-bottom: 28px;
      }
      .top-title-img-border {
        position: absolute;
        top: 32px;
        left: 80px;
      }
      .top-title-text {
        font-size: 16px;
        line-height: 22px;
        color: #FFFFFF;
        text-align: left;
      }
    }
  }
  .content-wrap {
    max-width: 1000px;
    margin: 0 auto 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 70px 0 130px;
    .side-bar {
      width: 194px;
      margin-right: 72px;
      flex-shrink: 0;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        width: 1px;
        min-height: calc(100% + 120px);
        background: #E5EBE6;
      }
      .side-bar-item {
        width: 100%;
        height: 64px;
        padding: 0 0 0 14px;
        display: flex;
        align-items: center;
        background-image: linear-gradient(90deg, rgba(255,255,255,0.00) 0%, rgba(255,255,255,0.74) 100%);
        position: relative;
        &.active {
          .label {
            color: #3CCF6E;
            &::after {
              z-index: 2;
              background-image: linear-gradient(180deg, #72F546 0%, #23BE7F 100%);
            }
          }
        }
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 14px;
          display: inline-block;
          width: calc(100% - 14px);
          height: 1px;
          background: #E5EBE6;
        }
        .icon {
          width: 6px;
          height: 6px;
          background-image: linear-gradient(90deg, #72F546 0%, #23BE7F 100%);
          border-radius: 50%;
          flex-shrink: 0;
          margin-right: 22px;
        }
        .label {
          user-select: none;
          cursor: pointer;
          flex: 1;
          font-size: 20px;
          height: 64px;
          line-height: 64px;
          color: #374567;
          letter-spacing: 0;
          text-align: left;
          font-weight: 500;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            display: inline-block;
            height: 100%;
            width: 2px;
            background: transparent;
          }
        }
      }
    }
    .content {
      flex: 1;
      .section {
        .section-title {
          font-size: 24px;
          color: #374567;
          text-align: left;
          font-weight: 550;
          line-height: 36px;
          margin-bottom: 34px;
          margin-top: 6px;
        }
        .section-content {
          font-size: 16px;
          color: #808996;
          letter-spacing: 0.1px;
          line-height: 30px;
          padding-bottom: 70px;
          p {
            margin-bottom: 1em;
          }
          .about-video-box {
            width: 100%;
            height: 100%;
            margin-bottom: 24px;
            border-radius: 12px;
            overflow: hidden;
            position: relative;
            #about-video {
              width: 100%;
              height: 100%;
              border-radius: 12px;
              overflow: hidden;
            }
          }
        }
        &.section-contact {
          .section-content {
            padding: 40px 96px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            background: #FFFFFF;
            box-shadow: 0px 2px 9px 1px rgba(0,0,0,0.02);
            border-radius: 16px;
            position: relative;
            overflow: hidden;
            .icon-img {
              width: 122px;
              height: 122px;
              margin-right: 110px;
            }
            .contact {
              div:first-child {
                font-size: 20px;
                color: #374567;
                letter-spacing: 0;
                text-align: left;
                font-weight: 550;
                margin-bottom: 6px;
              }
              div:nth-child(2) {
                font-size: 14px;
                color: #6E7B9B;
                letter-spacing: 0;
                text-align: left;
                margin-bottom: 10px;
              }
              .phone {
                font-size: 20px;
                color: #3CCF6E;
                letter-spacing: 0;
                text-align: left;
                font-weight: 500;
                margin-bottom: 8px;
                display: flex;
                align-items: center;
                &::before {
                  content: '';
                  display: inline-block;
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                  background: url("~@/assets/images/icon-phone.png") center/100% 100% no-repeat;
                }
              }
              .time {
                font-size: 14px;
                color: #999DA9;
                letter-spacing: 0;
                text-align: left;
                font-weight: 400;
              }
            }
            &::before {
              content: '';
              display: block;
              position: absolute;
              right: 42px;
              top: 24px;
              width: 78px;
              height: 78px;
              border-radius: 50%;
              transform: rotate(40deg);
              opacity: 0.41;
              transform: rotate(-48deg);
              background-image: linear-gradient(206deg, #ECFCF6 40%, #ECFCF6 43%, rgba(249,253,253,0.00) 100%);
            }
            &::after {
              content: '';
              display: block;
              position: absolute;
              right: -42px;
              bottom: -56px;
              width: 156px;
              height: 156px;
              border-radius: 50%;
              transform: rotate(40deg);
              background-image: linear-gradient(252deg, #ECFCF6 45%, rgba(249,253,253,0.00) 100%, rgba(249,253,253,0.00) 100%);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .about {
    .top-wrap .top-title {
      left: 15%;
    }
    .content-wrap {
      padding: 40px 20px 50px;
      .side-bar {
        display: none;
      }
      .content .section .section-content {
        padding-bottom: 40px;
      }
    }
  }
}

@media screen and (max-width: 666px) {
  .about {
    .top-wrap .top-title {
      left: 15%;
    }
    .content-wrap {
      padding: 40px 20px 50px;
      .side-bar {
        display: none;
      }
      .content .section {
        .section-content {
          padding-bottom: 20px;
        }
        &.section-contact .section-content {
          padding: 20px 20px;
          &::after{
            width: 100px;
            height: 100px;
          }
          .icon-img {
            margin-right: 6px;
            width: 72px;
            height: 72px;
          }
          .contact {
            transform: scale(0.8);
            z-index: 1;
            // .time {
            //   font-size: 12px;
            // }
          }
        }
      }
    }
  }
}
</style>