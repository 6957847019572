<template>
  <div id="app">
    <Navbar v-show="isShowNavbar"/>
    <router-view/>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar"
import { isWechat } from '@/utils'
export default {
  components: {
    Navbar
  },
  data() {
    return {
      isShowNavbar: true
    }
  },
  watch: {
    $route: {
      handler(val) {
        if(val.path == '/wxRedirect') {
          this.isShowNavbar = false
        } else {
          this.isShowNavbar = true
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    if(isWechat()) {
      document.addEventListener('WeixinJSBridgeReady',() => {
        this.$store.commit("SET_WeixinJSBridgeReady", true)
        // alert(this.$store.state.WeixinJSBridgeReady)
      });
    }
    
  }
}
</script>

<style lang="scss">
#app {
  position: relative;
  width: 100vw;
  height: 100vh;
  background: #f5f7fa;
  // min-width: 500px;
  overflow: auto;
}
</style>
