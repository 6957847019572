module.exports = {
  baseUrl: process.env.NODE_ENV === 'development' ? "/dev-api" : 'https://api.tuzidong.cn',
  // Web端体验
  webClientUrl: 'https://alifile.cdn.tuzidong.cn/WebMeta/Yunhuodong/922/CloudWeb/index.html?roomId=236225&compressLevel=5',
  // 管理端地址
  systemUrl: 'https://partner.tuzidong.cn/system',
  // 场景商城地址
  shopUrl: 'https://partner.tuzidong.cn/shop',
  // 虚拟场景文档
  virtualSceenDocUrl: 'https://dfw80d969m.feishu.cn/wiki/wikcnFFJvEaC3Hd1HncyOidehxe',
  // 数字虚拟人文档
  virtualHumanDocUrl: 'https://dfw80d969m.feishu.cn/wiki/wikcnmXqfQC9xO8NPABp5PXYmMe'
}
